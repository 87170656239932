@tailwind base;
@tailwind components;
@tailwind utilities;
/* CSS Spinner Animation */
@keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spinner 0.8s linear infinite;   
  }
  